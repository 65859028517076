import React, {useState} from 'react';

import {Button, InfoModal, Checkbox, Anchor} from '@newsela/angelou';
import {withTrackEvent, objectTypes, actionNames, actionPrefixes} from '@newsela/snitch';
import PropTypes from 'prop-types';

import {getAllRestrictedBandsCopy} from 'static/three-oh/src/modules/utils/assignmentHelpers';

import styles from './styles';
import {useContentAdvisoryMessage} from '../../../utils/contentAdvisoryMessage';
import LoadingShimmerMapper from '../LoadingShimmerMapper';

const ButtonWithTrackEvent = withTrackEvent(Button);

export const CatModal = ({contentId, catModalProps, postCatAssignment, setCatModalActive, conSetVisibilityMarkers}) => {
  // State:
  const [acceptedContentAdvisory, setAcceptedContentAdvisory] = useState(false);
  const restrictedBandsCopy = getAllRestrictedBandsCopy(conSetVisibilityMarkers || []);

  const tracking = {
    objectType: objectTypes.assignment,
    eventProperties: {
      content_id: contentId,
      description: 'Add content to assignments'
    },
    streamProperties: {
      description: 'Add content to assignment(s).',
      content_id: contentId,
      component_type: 'content-advisory-assignment',
      content_advisory_override_consent: contentId
    }
  };

  const {data: advisoryMessage, loading} = useContentAdvisoryMessage();

  const onCatClose = () => {
    setCatModalActive(false);
    setAcceptedContentAdvisory(false);
  };

  return (
    <InfoModal
      __classNameFor={{
        root: styles.rootModalCss,
        modalContentWrapper: styles.modalCss,
        footer: styles.modalFooterCss,
      }}
      onBackgroundClick={() => onCatClose()}
      closeButtonProps={{onClick: () => onCatClose()}}
      title={advisoryMessage.title}
      footer={
        <>
          <ButtonWithTrackEvent
            /* AUTOGENERATED TODO: update angelou to new flavor.
              see https://github.com/newsela/angelou/blob/main/src/components/Button/README.md#MIGRATION
              for migration guide. */
            legacy_flavor={Button.legacy_flavor.hollow}
            standard
            secondary
            key="cancel"
            label="Cancel"
            onClick={() => onCatClose()}
            actionPrefix={actionPrefixes.cancel}
            actionName={actionNames.update}
            legacyEventName="Assignment-ExitAddHeaderModal"
            {...tracking}
            dataQaSelector="cat-modal-cancel-btn"
          >
            Cancel
          </ButtonWithTrackEvent>
          <ButtonWithTrackEvent
            standard
            primary
            key="add"
            disabled={!acceptedContentAdvisory}
            label="Choose to assign"
            actionName={actionNames.chooseToAssign}
            legacyEventName="Assignment-Update"
            onClick={postCatAssignment}
            {...tracking}
            dataQaSelector="cat-modal-assign-btn"
          >
            Choose to assign
          </ButtonWithTrackEvent>
        </>
      }
      {...catModalProps}
    >
      <div className={styles.modalBodyText}>
        <LoadingShimmerMapper loading={loading}>
          <p>{advisoryMessage.body} {advisoryMessage.hyperlink ? <Anchor href={advisoryMessage.hyperlink.url}>{advisoryMessage.hyperlink.text}</Anchor> : null}</p>
        </LoadingShimmerMapper>
        <p>All students included in the assignment will be given access to this content.</p>
        {
          restrictedBandsCopy
            ? <p>{restrictedBandsCopy}</p>
            : null
        }
      </div>
      <Checkbox
        __classNameFor={{
          root: styles.rootCheckboxCss,
          labelText: styles.checkboxLabelTextCss,
          emptyCheckbox: styles.checkboxCss,
          checkedBox: styles.checkboxCss,
        }}
        checked={acceptedContentAdvisory}
        label="I've read this message and choose to assign the content."
        value="I've read this message and choose to assign the content."
        onClick={() => {
          setAcceptedContentAdvisory(!acceptedContentAdvisory);
        }}
      />
    </InfoModal>
  );
};

CatModal.propTypes = {
  contentId: PropTypes.string,
  catModalProps: PropTypes.object,
  postCatAssignment: PropTypes.function,
  setCatModalActive: PropTypes.function,
  conSetVisibilityMarkers: PropTypes.arrayOf(PropTypes.object)
};
