import React from 'react';

import {Anchor, Button} from '@newsela/angelou';
import {withTrackEvent, actionNames, objectTypes, actionPrefixes} from '@newsela/snitch';
import {isEmpty, get} from 'lodash';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router';
import {bindActionCreators} from 'redux';

import {setModalId} from 'modulesV2/actions';
import {getExternalToolContext} from 'modulesV2/selectors';
import ButtonSplit from 'static/three-oh/src/components/ButtonSplit';
import {lyceumOrigin} from 'static/three-oh/src/constants/lyceum';
import {stringifyParams} from 'static/three-oh/src/modules/apis/ApiFetcher2';
import fetcher3 from 'static/three-oh/src/modules/apis/ApiFetcher3';
import {getUserProperties} from 'static/three-oh/src/modules/selectors/userSelectors';
import AngelouProps from 'static/three-oh/src/propTypes/AngelouPropTypes';

import {disabledButtonCss} from './style';



const propTypesAssignButton = {
  ...AngelouProps,
  label: PropTypes.string,

  /**
   * There are three
   * types of button: Standard,
   * Rounded, and Flat
   */
  standard: PropTypes.bool,
  rounded: PropTypes.bool,
  flat: PropTypes.bool,

  /**
  * A button of any type
  * can take on a primary style,
  * or a secondary style. A user
  * can also pass in a className
  * for additional customized
  * styles
  * */
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  disabled: PropTypes.bool,

  setModalId: PropTypes.func,
  contentId: PropTypes.string,

  isLiteUser: PropTypes.bool,
  isTeacher: PropTypes.bool,
  location: PropTypes.object,
  searchId: PropTypes.string,

  externalToolContext: PropTypes.shape({
    action: PropTypes.string, // Option to sync classroom or create assignment.
    courseId: PropTypes.string,
    courseTitle: PropTypes.string,
    provider: PropTypes.string,
  })

};

const AnchorWithTrackEvent = withTrackEvent(Anchor);
class AssignButton extends React.Component {
  state = {
    contentIds: [],
    visibilityMarkers: [],

    // Add variable that will indicate the content api is in loading state, so the
    // button can be disabled until the content checks. Set as default true because the
    // componentDidMount have a delay to run the setState to true, and can cause a effect
    // where the button will be displayed and disabled after some seconds.
    loadingContent: true
  }

  componentDidMount() {
    this.getContentInformations().then((requestData) => {
      const contentIds = this.handleContentIdsMembers(requestData);
      const visibilityMarkers = this.handleContentIdsVisibilityMarkers(requestData);
      this.setState({contentIds, visibilityMarkers, loadingContent: false});
    });
  }

  // TODO: real error handling, was added to get a test to pass
  // TODO: Consider to use ContentApi instead manual fetcher3?
  async getContentInformations() {
    const {contentId} = this.props;
    const {data: content} = await fetcher3
      .get(`content/${contentId}/`, {depth: '1', shape: 'outline'})
      .catch(() => ({
        data: {
          members: [],
          visibility_markers: []
        }
      }));
    return content;
  }

  handleContentIdsMembers(content) {
    const {contentId} = this.props;
    let memberIds;
    if (content?.members) {
      memberIds = content?.members.map((member) => member.content_id);
    }

    return !isEmpty(memberIds) ? memberIds : [contentId];
  }

  handleContentIdsVisibilityMarkers(content) {
    return content.visibility_markers?.length > 0 ? content.visibility_markers : [];
  }

  getCreateAssignmentUrl() {
    const location = window.location;

    const urlParams = {
      return_url: location.pathname + location.search,
      createdFrom: this.props.contentId,
      contentIds: this.state.contentIds,
      ...this.props.externalToolContext,
    };
    const params = stringifyParams(urlParams);

    // Build url to Lyceum with content params
    return `${lyceumOrigin}/assignments/create${params}`;
  }

  navigateToCreateAssignment() {
    window.location.href = this.getCreateAssignmentUrl();
  }

  isButtonDisabledForLiteUser() {
    const isPremiumVailed = (!!this.state.visibilityMarkers.find(({reason}) => reason === 'PREMIUM_CONTENT')) && this.props.isLiteUser;
    const checkValue = isPremiumVailed || this.props.disabled;
    return checkValue || this.state.loadingContent;
  }

  getButtonType() {
    const btnType = {
      standard: this.props.standard,
      rounded: this.props.rounded,
      flat: this.props.flat,
      primary: this.props.primary,
      secondary: this.props.secondary,
      disabled: this.props.disabled,
    };
    return btnType;
  }

  /**
   * Return the Assign Anchor for lite users
   * and re-directs to create assignment flow
   */
  renderAssignAnchor() {
    const isTextSet = this.props.contentType === 'textset';
    const isButtonDisabled = this.isButtonDisabledForLiteUser();
    const enabledButtonProps = {href: this.getCreateAssignmentUrl()};
    const disabledButtonProps = {
      ariaProps: {
        'aria-disabled': true,
        role: 'link'
      },
      __cssFor: {
        root: disabledButtonCss
      }
    };

    return (
      <AnchorWithTrackEvent
        styleAnchorAsButton={{
          flavor: isTextSet ? Button.flavors.primary : Button.flavors.tertiary,
          size: isTextSet ? Button.sizes.lg : Button.sizes.sm
        }}
        {...(isButtonDisabled ? disabledButtonProps : enabledButtonProps)}
        eventProperties={{
          objectType: objectTypes.assignment,
          actionPrefix: actionPrefixes.initiate,
          actionName: actionNames.navigate,
          streamProperties: {
            content_id: this.props.contentId,
            description: 'Open create assignment flow',
            search_id: this.props.searchId || get(this.props.location.state, 'search_id'),
          }
        }}
      >
        {isTextSet ? 'Create Assignment' : 'Assign'}
      </AnchorWithTrackEvent>
    );
  }

  render() {
    const openModal = () => this.props.setModalId('AddContentToAssignmentModal', {
      createdFrom: this.props.contentId,
      contentIds: this.state.contentIds
    });

    const options = [
      {
        label: 'Create Assignment',
        onClick: () => this.navigateToCreateAssignment(),
      },
      {
        label: 'Add to Assignment',
        onClick: openModal
      },
    ];

    if (!(this.props.isTeacher || this.props.isParent)) {
      return null;
    }

    return (
      <>
        {this.props.isLiteUser
          ? this.renderAssignAnchor()
          : <ButtonSplit
              buttonElementProps={{
                ...this.props.buttonElementProps
              }}
              contentType={this.props.contentType}
              data-qa-selector="create_assignment"
              legacyEventName="Assignment-InitiateAddHeader"
              eventProperties={{
                content_id: this.props.contentId,
                description: 'Add content to assignment.',
                search_id: this.props.searchId || get(this.props.location.state, 'search_id'),
              }}
              objectType={objectTypes.assignment}
              actionPrefix={actionPrefixes.initiate}
              actionName={actionNames.update}
              streamProperties={{
                content_id: this.props.contentId,
                description: 'Add content to assignment.',
                search_id: this.props.searchId || get(this.props.location.state, 'search_id'),
              }}
              onClick={() => this.navigateToCreateAssignment()}
              {...this.getButtonType()}
              label={this.props.label}
              options={options}
            />}
      </>
    );
  }
}

AssignButton.propTypes = propTypesAssignButton;
AssignButton.defaultProps = {
  primary: true,
  rounded: true,
  disabled: false,
};

AssignButton.displayName = 'AssignButton';

const MapStateToProps = (state) => {
  return {
    externalToolContext: getExternalToolContext(state),
    isTeacher: getUserProperties(state).isTeacher,
    isParent: getUserProperties(state).isParent,
    isLiteUser: getUserProperties(state).isLiteUser,
  };
};

const actions = {
  setModalId,
};

function mapDispatchToProps(dispatch) {
  return {
    dispatch,
    ...bindActionCreators(actions, dispatch),
  };
}

export default connect(MapStateToProps, mapDispatchToProps)(withRouter(AssignButton));
